.footer {
  width: 100%;
  height: 40px;
  @media (max-width: 768px) {
    height: 70px;
    background-color: $baseDarkGray;
  }
}

.footer-col {
  width: 100%;

  & .footer-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      font-size: 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    //if screen < 768px display flex

    & .footer-li {
      display: flex;
      flex-direction: row;
      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: $baseGreen;
        font-size: 1rem;
        & a {
          color: $baseGreen;
          font-size: 1.2rem;
          text-decoration: none;
        }
      }
    }
  }

  & ul li a {
    @media (min-width: 768px) {
      font-size: 1rem;
      text-transform: capitalize;
      text-decoration: none;
      font-weight: 500;
      color: #413f3f;
      display: block;
      transition: all 0.3s ease;

      & .span-company {
        font-size: 1rem;
        font-weight: 600;
        color: #293f64;

        &:hover {
          font-size: 1rem;
          font-weight: 600;
          color: $baseGreen;
        }
      }

      &:hover {
        border-bottom: 2px solid $baseGreen;
        color: $baseGreen;
        padding-top: 2px;
        font-size: 17px;
      }
    }
  }
}
