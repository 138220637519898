.header-desktop {
  @media only screen and (max-width: 770px) {
    display: none;
  }
}

.container-hide-mobile {
  @media only screen and (max-width: 770px) {
    display: none;
  }
}

.sidebar-nav .nav-link {
  //hover and active
  &:hover {
    background-color: $baseGreen;
  }
}

.sidebar-nav .nav-item .nav-link.active {
  background-color: $baseGreen;
}
.header-mobile {
  @media only screen and (max-width: 770px) {
    display: block !important;
  }

  @media only screen and (min-width: 770px) {
    display: none !important;
  }
}
.logo-container {
  position: absolute;
  left: auto;
  top: 10px;
  color: $baseGreen;
}

.menu-container {
  position: absolute;
  right: 0;
  top: 15px;
  color: $baseGreen;
}

.links-container {
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.3rem;
}
