// Here you can add other styles
.login-page {
  background: url('../assets/compres.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.color-text-blue {
  color: #003f6e;
}
.color-container-blue {
  background-color: #003f6e !important;
}

.product-table-container {
  padding: 0 12px;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70vh;
}

.custom-scroll {
  height: 100%;

  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-moz-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #fefefe;
  }

  &::-moz-scrollbar-track {
    background: #fefefe;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #3c4b64; /* Set the color of the thumb  color from sidebar*/
    background-color: lightgray;
  }
  &::-moz-scrollbar-thumb {
    // background-color: #3c4b64; /* Set the color of the thumb */
    background-color: lightgray;
  }
  scrollbar-width: thin !important;
}
.animation-container {
  transition: visibility 0.5s ease-in-out;
}
.paintColorGreenonHoover {
  &:hover {
    color: $baseGreen !important;
  }
}
.paintBackgroundGreenonHoover {
  &:hover {
    background-color: $baseGreen !important;
  }
}

.padding-bottom-container-mobile {
  @media only screen and (max-width: 770px) {
    padding-bottom: 1rem !important;
  }
}

.padding-left-container-mobile {
  @media only screen and (max-width: 770px) {
    padding-left: 1rem !important;
  }
}

.margin-bottom-container-mobile {
  @media only screen and (max-width: 770px) {
    margin-bottom: 1rem !important;
  }
}

.w-100 {
  width: 100% !important;
}
.align-content-end {
  display: flex;
  justify-content: flex-end;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardCategoriesTable {
  //set the height of the card if the width is bigger than 1024px
  @media only screen and (min-width: 1024px) {
    max-height: 900px;
  }
}

.cardProductsTable {
  @media only screen and (min-width: 1024px) {
    height: 900px;
    max-height: 900px;
    overflow-y: auto;
  }
}
