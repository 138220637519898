.ant-button .ant-button-primary {
  background-color: $baseDarkGray;
}

.custom-button {
  background-color: $baseDarkGray !important;
  color: #fff !important;
  &:hover {
    background-color: $baseGreen !important;
  }
}

.sidebar-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  border-radius: 50%;
  font-size: 0.8rem;
  background-color: $baseGreen !important;
  color: $baseDarkGray !important;
  width: 25px;
  height: 25px;

  &:hover {
    cursor: pointer;
    animation: pulse 1s ease-in-out;
  }
}

.ant-pagination-item-active {
  background-color: $baseGreen !important;
  color: #fff;
  border: none !important;
}

.customize-table {
  min-height: 65vh;
  overflow-x: auto;
}

.sidebar {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
.new_background {
  background-color: $baseBackground !important;
}

.ant-progress-inner {
  display: flex !important;
}

.card-stats {
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100% !important;
  }
}

//declare animation pulse
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.scroll-x-mobile {
  @media only screen and (max-width: 768px) {
    overflow-x: auto;
  }
}
