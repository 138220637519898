.card {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  @media (max-width: 768px) {
    width: 100% !important;
  }
  &.card-c {
    @media (max-width: 768px) {
      min-height: 220px;
    }
  }
}

.card-body-s {
  display: flex;
  justify-content: space-between;
}
.card-f-line {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: -0.8rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}
.card-s-line {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}
.card-td-line {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}
.icon-container {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
